html, body {
    height: 100%;
    margin: 0;
}

body {
    font: 10pt Graphik, sans-serif;
}

.ol-zoom button {
    font: 20pt Graphik, sans-serif !important;
}

.map {
    width: 100%;
    height: 100%;
}

.ol-control {
    background: none !important;
}

.layer-switcher button {
    display: none !important;
}

.layer-switcher ul, .ol-zoom {
    display: flex;
    flex-direction: column;
}

.layer-switcher {
    right: 10px !important;
}

.ol-zoom, .layer-switcher {
    bottom: .5em !important;
    top: auto !important;
}

.layer-switcher ul {
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;
}

.layer-switcher label, .ol-zoom button {
    margin: 0 !important;
}

.ol-control button, .layer-switcher li label {
    background: #F0F3F3 !important;
    color: black !important;
    text-align: center !important;
    border: 1px solid #3d57cc !important;
    min-width: 40px;
    height: 40px !important;
    border-radius: 0 !important;
    padding: 5px;
}

/* TODO make this a radio button!!  */
.layer-switcher li input[type="checkbox"] {
    display: none;
}

.layer-switcher li input[type="checkbox"]:checked + label {
    background: #3d57cc !important;
    color: white !important;
    font-weight: bold;
}

.layer-switcher li label {
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#logo {
    width: 50px;
    position: absolute;
    left: 5px;
    top: 5px;
}

#logo img {
    width: 100%;
}

#compass {
    position: absolute;
    top: 10px;
    right: 10px;
}

#compass img {
    width: 25px;
}

#legend-container {
    display: flex;
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 0.5em;
    background-color: rgba(255,255,255,1);
    z-index: 999;
    text-align: center;
    padding: .75em;

}

#legend-container .arrow {
    width: 150px;
}

.legend-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.legend-entry {
    display: flex;
    padding: 0.5em;
}

.legend-entry:last-child {
    padding-right: 0;
}

.legend-text {
    padding: 0 0.5em;
}


.ol-attribution {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    bottom: 0.5em;
    width: 100%;
}

.ol-attribution ul {
    text-align: center;
}

@media (min-width: 568px) {

    #logo {
        width: 70px;
    }


    .layer-switcher ul, .ol-zoom {
        flex-direction: row-reverse;
    }

    .ol-control button, .layer-switcher li label {
        padding: 10px;
    }

}

#logo, #compass, #legend img, #legend p {
    z-index: 1;
}

div.ol-rotate {
    display: none;
}

@font-face {
    font-family: "Druk";
    font-weight: bold;
    src: url("assets/fonts/Druk-Bold-Web.woff") format("woff");
}

@font-face {
    font-family: "Graphik";
    font-weight: normal;
    src: url("assets/fonts/Graphik-Regular-Web.woff") format("woff");
}

@font-face {
    font-family: "Graphik";
    font-weight: bold;
    src: url("assets/fonts/Graphik-Semibold-Web.woff") format("woff");
}

#tfm-sidebar {
    position: fixed;
    z-index: 999;
    background: white;
    padding: 0 30px;
    overflow-y: auto;
    border: 0 solid #6a6a71;
}

#tfm-sidebar button {
    font: bold 14px Graphik, sans-serif;
    color: white;
    padding: 15px;
    margin: 10px 0;
    border: 0;
}

#tfm-sidebar::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

#tfm-sidebar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

#tfm-sidebar img, #tfm-sidebar button {
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

#tfm-sidebar.closed {
    visibility: hidden;
}

#tfm-sidebar.open {
    visibility: visible;
}

#tfm-sidebar #sidebar-content {
    margin-bottom: 5em;
    font: 12pt Graphik, sans-serif;
}

@media only screen and (min-width: 501px) {
    #tfm-sidebar {
        transition: width 0.5s;
    }

    #tfm-sidebar.open {
        width: 40vw;
        max-width: 300px;
        border-left-width: 4px;
        right: 0;
        height: 100vh;
    }

    #tfm-sidebar.closed {
        width: 0;
    }

    #legend-container .arrow.mobile {
        display: none;
    }
}


@media only screen and (max-width: 500px) {
    #tfm-sidebar {
        transition: height 0.5s;
        background: linear-gradient(0deg,  rgba(0,0,0,1) 0%, rgba(255,255,255,1) 2em);
    }

    #tfm-sidebar.open {
        width: 100vw;
        max-width: calc(100% - 60px);
        bottom: 0;
        height: 70vh;
        border-top-width: 4px;
    }

    #tfm-sidebar.closed {
        height: 0;
    }

    #legend-container {
        background: none;
        padding: 0;
    }

    .legend-text {
        white-space: nowrap;
    }

    .legend-entry {
        display: none;
    }

    #legend-container .arrow.mobile {
        width: 20px;
        transform: rotate(90deg);
        margin-right: 1em;
        margin-left: 2em;
    }

    #legend-container .arrow.desktop {
        display: none;
    }

}

#tfm-sidebar #sidebar-close-button {
    font-size: 20pt;
    font-weight: bold;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: black;
}

